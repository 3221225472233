import { PlatformAppsModule } from "../PlatformAppsModule";

export default class ThemeModule extends PlatformAppsModule {
  getTheme() {
    console.warn(
      "Method not implemented: v1.Theme.getTheme -- returning 'night'",
    );
    return "night";
  }

  themeChanged() {
    console.warn("Method not implemented: v1.App.themeChanged");
  }
}
