import type PlatformApps from "../PlatformApps";
import { PlatformAppsModule } from "../PlatformAppsModule";
import {
  Source,
  type TSourceSettings,
  type TSourceType,
} from "~/lib/themes/obs";

export type PlatformAppsSource = {
  id: string;
  name: string;
  type: TSourceType;
  flags: {
    audio: boolean;
    video: boolean;
    async: boolean;
  };
  size: {
    width: number;
    height: number;
  };
  appId: string;
  appSourceId: string;
};

function mapToPlatformAppsSource(source: {
  settings: TSourceSettings;
  type: TSourceType;
  name: string;
}) {
  return {
    id: source.name,
    name: source.name,
    type: source.type,
    flags: {
      audio: false,
      video: false,
      async: false,
    },
    size: {
      width: 0,
      height: 0,
    },
    appId: source.settings?.__appId,
    appSourceId: source.settings?.__appSourceId,
  };
}

export default class SourcesModule extends PlatformAppsModule {
  async getSource(sourceId: string): Promise<PlatformAppsSource> {
    const source = await Source.findByIdWithSettings(sourceId);
    return mapToPlatformAppsSource(source);
  }

  async getSources(): Promise<PlatformAppsSource[]> {
    const sources = await Source.allWithSettings();

    return sources.map(mapToPlatformAppsSource);
  }

  async getAppSources(): Promise<PlatformAppsSource[]> {
    const allSources = await this.getSources();

    return allSources.filter((item) => {
      return item.appId === this.platformApps.installedApp.id_hash;
    });
  }

  async createAppSource(
    name: string,
    appSourceId: string,
  ): Promise<PlatformAppsSource> {
    const { installedApp } = this.platformApps;
    const appId = installedApp.id_hash;

    const appSourceManifest = installedApp.manifest.sources.find(
      (source) => source.id === appSourceId,
    );

    if (!appSourceManifest) {
      throw new Error(`Could not find app source in manifest`);
    }

    let settings: TSourceSettings = {
      __appId: appId,
      __appSourceId: appSourceId,
    };

    switch (appSourceManifest.initialSize?.type) {
      case "absolute":
        settings.width = appSourceManifest.initialSize.width;
        settings.height = appSourceManifest.initialSize.height;
        break;
      case "relative":
        settings.width = appSourceManifest.initialSize.width * 1920;
        settings.height = appSourceManifest.initialSize.height * 1080;
        break;
      default:
        settings.width = 800;
        settings.height = 600;
        break;
    }

    switch (appSourceManifest.type) {
      case "browser_source":
        settings.url = getAppFileUrl(installedApp, appSourceManifest.file);
        break;
    }

    const source = await Source.create(appSourceManifest.type, name, settings);

    settings = await source.getSettings();

    return mapToPlatformAppsSource({
      ...source,
      settings,
    });
  }

  async updateSource(patch: {
    id: string;
    name?: string;
    muted?: boolean;
    volume?: number;
    monitoringType?: "none" | "monitor-only" | "monitor-and-output";
  }) {
    console.warn("Method not implemented: v1.Sources.updateSource");

    return await Promise.resolve();
    // ...
  }

  sourceAdded(fn: (source: PlatformAppsSource) => void) {
    useObsStore().emitter.on("sourceAdded", ({ name }) => {
      Source.findByIdWithSettings(name).then((source) =>
        fn(mapToPlatformAppsSource(source)),
      );
    });
  }

  sourceRemoved(fn: (sourceId: PlatformAppsSource["id"]) => void) {
    useObsStore().emitter.on("sourceRemoved", fn);
  }

  sourceUpdated(fn: (source: PlatformAppsSource) => void) {
    console.warn("Method not implemented: v1.Sources.sourceUpdated");
  }

  getAvailableSourceTypes(): string[] {
    console.warn("Method not implemented: v1.Sources.getAvailableSourceTypes");
    return [];
  }

  async setAppSourceSettings(
    sourceId: string,
    settingsJsonStr: string,
  ): Promise<void> {
    const appSources = await this.getAppSources();
    const appSource = appSources.find((source) => source.id === sourceId);

    if (!appSource) {
      throw new Error(`Could not find app source with id ${sourceId}`);
    }

    const { installedApp } = this.platformApps;
    const appId = installedApp.id_hash;

    const appSourceManifest = installedApp.manifest.sources.find(
      (source) => source.id === appSource.appSourceId,
    );

    if (!appSourceManifest) {
      throw new Error(`Could not find app source in manifest`);
    }

    const url = getAppFileUrl(
      installedApp,
      appSourceManifest.file,
      settingsJsonStr,
    );

    console.log({ url });

    await this.setObsSettings(sourceId, {
      __appSourceSettingsJsonStr: settingsJsonStr,
      url,
    });
  }

  async getAppSourceSettings(sourceId: string): Promise<string> {
    const source = await Source.findById(sourceId);
    const settings = await source.getSettings();

    return settings.__appSourceSettingsJsonStr || "";
  }

  async setObsSettings(
    sourceId: string,
    settings: TSourceSettings,
  ): Promise<void> {
    const source = await Source.findById(sourceId);
    await source.update(settings);
  }
}
