import { PlatformAppsModule } from "../PlatformAppsModule";

export default class AppModule extends PlatformAppsModule {
  onNavigation() {
    console.warn("Method not implemented: v1.App.onNavigation");
  }

  navigate(...args: any[]) {
    console.log("v1.App.navigate", { args });
    console.warn("Method not implemented: v1.App.navigate");
  }
}
