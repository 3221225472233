import { PlatformAppsModule } from "../PlatformAppsModule";
import Bindings from "~/lib/bindings";
import { Scene } from "~/lib/themes/obs";

type Vec2 = { x: number; y: number };

type SceneItemFolder = {
  id: string;
  type: "folder";
  chilrenIds: string[];
};

type SceneItemTransformCrop = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type SceneItemTransform = {
  position: Vec2;
  scale: Vec2;
  crop: SceneItemTransformCrop;
  rotation: number;
};

type SceneItem = {
  id: string;
  type: "scene_item";
  sourceId: string;
  visible: boolean;
  locked: boolean;
  transform: SceneItemTransform;
};

export type PlatformAppsScene = {
  id: string;
  name: string;
  nodes: (SceneItem | SceneItemFolder)[];
};

async function mapToPlatformAppsScene(
  scene: Scene,
): Promise<PlatformAppsScene> {
  const sources = await scene.getSources();

  const platformAppsScene: PlatformAppsScene = {
    id: scene.name,
    name: scene.name,
    nodes: [],
  };

  for (const { source, settings } of sources) {
    const type = source.id;
    const id = source.name;

    if (type === "group") {
      platformAppsScene.nodes.push({
        id,
        type: "folder",
        chilrenIds: settings.items.map((item: { name: string }) => item.name),
      });

      continue;
    }

    platformAppsScene.nodes.push({
      id,
      type: "scene_item",
      sourceId: source.name,
      visible: true,
      locked: true,
      transform: {
        position: {
          x: 0,
          y: 0,
        },
        scale: {
          x: 1,
          y: 1,
        },
        crop: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        rotation: 0,
      },
    });
  }

  return platformAppsScene;
}

export default class ScenesModule extends PlatformAppsModule {
  sceneSwitched() {
    console.warn("Method not implemented: v1.Scenes.sceneSwitched");
  }

  async getScenes(): Promise<PlatformAppsScene[]> {
    const slabsScenes = await Scene.all();
    return await Promise.all(slabsScenes.map(mapToPlatformAppsScene));
  }

  async getActiveScene(): Promise<PlatformAppsScene> {
    const activeScene = await Scene.getActive();
    return mapToPlatformAppsScene(activeScene);
  }

  removeSceneItem(sceneId: string, sceneItemId: string) {
    console.warn("Method not implemented: v1.Scenes.removeSceneItem");
  }

  async createSceneItem(sceneId: string, sourceId: string): Promise<SceneItem> {
    try {
      await Bindings.obs.scene_add(sceneId, sourceId);
    } catch (err) {
      console.error(err);
    }
    console.log("past it");
    return {
      id: sourceId,
      type: "scene_item",
      sourceId,
      visible: true,
      locked: true,
      transform: {
        position: {
          x: 0,
          y: 0,
        },
        scale: {
          x: 1,
          y: 1,
        },
        crop: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        rotation: 0,
      },
    };
  }
}
