import Bindings, { openBrowserTo } from "~/lib/bindings";
import { onMessageFromTab } from "~/lib/core/on-message-from-tab";
import PlatformApps from "~/lib/platform-apps/PlatformApps";
import { sleep } from "~/lib/util";
import { deterministicUUIDv4 } from "~/lib/uuid";

enum EAppSourceType {
  Browser = "browser_source",
}

/**
 * Presentational data for the sources showcase in SLOBS
 */
interface IAppSourceAbout {
  description: string;
  bullets: string[];
  bannerImage?: string;
}

enum ESourceSizeType {
  Absolute = "absolute",
  Relative = "relative",
}

export enum EAppPageSlot {
  /**
   * The top nav slot is mounted in the main app nav
   */
  TopNav = "top_nav",

  /**
   * The chat slot is mounted in the live dock
   */
  Chat = "chat",

  /**
   * The background slot is never mounted anywhere
   */
  Background = "background",

  /**
   * Auxillary slot for functionality handled in a different window
   */
  PopOut = "pop_out",
}

interface IAppPage {
  slot: EAppPageSlot;
  persistent?: boolean;
  allowPopout?: boolean; // Default true
  file: string; // Relative path to HTML file
  popOutSize?: {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
  };
}

type InstalledAppsResponse = {
  id_hash: string;
  name: string;
  app_token: string;
  icon: string;
  screenshots: string[];
  is_beta: boolean;
  cdn_url: string;
  version: string;
  manifest: {
    name: string;
    pages: IAppPage[];
    sources: {
      type: EAppSourceType;
      id: string; // A unique id for this source
      name: string;
      about: IAppSourceAbout;
      file: string; // Relative path to HTML file
      initialSize?: {
        type: ESourceSizeType;
        width: number;
        height: number;
      };
      redirectPropertiesToTopNavSlot: boolean;
    }[];
    version: string;
    buildPath: string;
    permissions: ("slobs.external-links" | string)[];
    supportedPlatforms: "twitch"[];
  };
  delisted: boolean;
}[];

const mapInstalledApps = (
  apps: InstalledAppsResponse,
  items: LibraryItemApplication[],
) => {
  return apps.map((app) => {
    const libraryItem = items.find((theme) => theme.object.id === app.id_hash);

    return {
      ...app,
      item: libraryItem,
      icon: libraryItem
        ? `https://platform-cdn.streamlabs.com/app-icons/${libraryItem.id}.png`
        : undefined,
    };
  });
};

export type InstalledApp = ReturnType<typeof mapInstalledApps>[number];

export const getAppFileUrl = (
  installedApp: InstalledApp,
  fileName: string,
  settingsStr?: string,
) => {
  const params = [`app_token=${installedApp.app_token}`];

  if (settingsStr) {
    params.push(`settings=${encodeURIComponent(settingsStr)}`);
  }

  return [installedApp.cdn_url, `${fileName}?${params.join("&")}`].join("/");
};

export const useAppsStore = defineStore("apps", () => {
  const api = useApiStore();
  const { public: $env } = useRuntimeConfig();

  const carouselApps = useDeferredAsyncData("carousel-apps", async () => {
    const { data } = await api.get<CarouselItem[]>(
      `${$env.MARKETPLACE_ORIGIN}/api/v2/dashboard/carousel?type=applications`,
    );

    return data;
  });

  const installedApps = useDeferredAsyncData("installed-apps", async () => {
    const { data: apps } = await api.get<InstalledAppsResponse>(
      `${$env.LEGACY_APP_PLATFORM_ORIGIN}/api/v1/sdk/installed_apps`,
    );

    const url = new URL(
      `${$env.MARKETPLACE_ORIGIN}/api/v2/legacy/app-store/themes`,
    );

    url.search = apps.map(({ id_hash: id }) => `id[]=${id}`).join("&");

    const { data: items } = await api.get<LibraryItemApplication[]>(
      url.toString(),
    );

    return mapInstalledApps(apps, items);
  });

  const installApp = async (item: LibraryItemApplication) => {
    // const platformAuthToken = await getPlatformAuthToken();
    // await api.post(
    //   `${$env.LEGACY_APP_PLATFORM_ORIGIN}/api/v1/app/${item.object.id}/install`,
    //   undefined,
    //   {
    //     headers: { Authorization: `Bearer ${platformAuthToken}` },
    //     withCredentials: true,
    //   },
    // );

    await api.post(
      `${$env.MARKETPLACE_ORIGIN}/api/v2/themes/${item.id}/install`,
    );

    await installedApps.refresh();
  };

  const uninstallApp = async (item: LibraryItemApplication) => {
    if (installedApps.data.value) {
      installedApps.data.value = installedApps.data.value?.filter(
        (app) => app.item?.id !== item.id,
      );
    }

    // const platformAuthToken = await getPlatformAuthToken();

    // await api.post(
    //   `${$env.LEGACY_APP_PLATFORM_ORIGIN}/api/v1/app/${item.object.id}/uninstall`,
    //   undefined,
    //   { headers: { Authorization: `Bearer ${platformAuthToken}` } },
    // );

    await api.post(
      `${$env.MARKETPLACE_ORIGIN}/api/v2/themes/${item.id}/uninstall`,
    );

    await installedApps.refresh();
  };

  const ensureAppInstalled = async (item: LibraryItemApplication) => {
    const installedApp = installedApps.data.value?.find(
      (app) => app.item?.id === item?.id,
    );

    if (installedApp) {
      return installedApp;
    }

    await installApp(item);

    return installedApps.data.value?.find((app) => app.item?.id === item.id);
  };

  const getInstalledAppUrl = (
    installedApp: NonNullable<Awaited<ReturnType<typeof ensureAppInstalled>>>,
  ) => {
    const fileName = installedApp.manifest.pages.find(
      (page) => page.slot === "top_nav",
    )?.file;

    if (!fileName) {
      throw new Error(`no slot found for ${installedApp.manifest.name}`);
    }

    return getAppFileUrl(installedApp, fileName);
  };

  const tabUidToLibraryItemApplication = new Map<
    number,
    LibraryItemApplication
  >();

  onMessageFromTab(async (payload, uid) => {
    const request = JSON.parse(payload) as TabsRequest;

    const item = tabUidToLibraryItemApplication.get(uid);

    const installedApp = installedApps.data.value?.find(
      (app) => app.item?.id === item?.id,
    );

    if (!installedApp) {
      console.warn(`Could not find installed app for ${item?.id}`);
      return;
    }

    const respond = (status: "resolved" | "rejected", payload: any) => {
      Bindings.tabs.sendStringToTab(
        uid,
        JSON.stringify({
          request,
          status,
          at: new Date(),
          payload,
        }),
      );
    };

    const callFn = (fnId: string, args: any[]) => {
      console.log(`[CALL FN] ${fnId}`, { args });
      const payload = {
        type: "fn",
        fnId,
        args,
      };

      console.log("sendStringToTab", payload);
      Bindings.tabs.sendStringToTab(uid, JSON.stringify(payload));
    };

    const resolve = (payload?: any) => respond("resolved", payload);
    const reject = (payload?: any) => respond("rejected", payload);

    console.log(request.payload.method, { request });

    if (!request.payload || !("method" in request.payload)) {
      console.warn("method is missing from payload");
      return;
    }

    if (request.payload.method === "v1.App.reload") {
      console.log("RELOADING");

      if (!item) {
        const err = new Error(`Fatal error: ${request.payload.method}`);
        reject(err);
        throw err;
      }

      await Bindings.tabs.destroyWindow(uid).then(async () => {
        await sleep(1000);
        await launchApp(item);
      });

      return;
    }

    const methodPath = request.payload.method.split(".");

    let target: any = new PlatformApps(installedApp);

    for (const part of methodPath) {
      if (!(part in target)) {
        const err = new Error(`Missing definition: ${request.payload.method}`);
        reject(err);
        throw err;
      }

      if (part === "then") {
        // ...
      } else {
        target = target?.[part];
      }
    }

    if (target && typeof target.then === "function") {
      console.log(methodPath, "target is a promise");
      target.then(
        (...args: any[]) => {
          console.log("promise resolved");
          callFn(request.payload.args[0], args);
        },
        (...args: any[]) => {
          console.log("promise rejected");
          callFn(request.payload.args[1], args);
        },
      );
    } else if (typeof target === "function") {
      console.log(methodPath, "is a function");

      const args = request.payload.args.map((arg) => {
        if (typeof arg === "string" && arg.startsWith("FN_")) {
          return (...args: any[]) => callFn(arg, args);
        }

        return arg;
      });

      const payload = await target(...args);
      console.log(request.payload.method, { response: payload });
      return resolve(payload);
    } else {
      console.log(methodPath, "is not a function or promise");
      console.log({ target, type: typeof target });
      return resolve(target);
    }
  });

  const launchApp = async (
    item: LibraryItemApplication,
    options?: {
      hideOnLaunch?: boolean;
    },
  ) => {
    const installedApp = await ensureAppInstalled(item);

    if (!installedApp) {
      console.error(`installedApp not found`, installedApp);
      return;
    }

    const appUrl = getInstalledAppUrl(installedApp);

    if (window.slabsGlobal) {
      const currentTabs = await Bindings.tabs.queryAll();

      const currentTab = currentTabs.find((tab) =>
        tab.url.includes(`/${installedApp.id_hash}/`),
      );

      console.log({ currentTabs, installedApp, currentTab });

      if (currentTab) {
        console.log("app already open: ", currentTab);

        if (!options?.hideOnLaunch) {
          await Bindings.tabs.showWindow(currentTab.uid);
        }

        await Bindings.tabs.executeJs(
          currentTab.uid,
          `window.emitter.emit("v1.App.onNavigation");`,
        );

        return;
      }

      const uid = Math.floor(Math.random() * 10000);

      let iconPath: string | undefined;

      if (installedApp.icon) {
        const basename = `${deterministicUUIDv4(installedApp.icon)}`;
        const extension = new URL(installedApp.icon).pathname.match(
          /\.([0-9a-z]+)(?:[?#]|$)/i,
        )?.[1];

        const filename = `${basename}${extension ? `.${extension}` : ""}`;

        iconPath = await Bindings.fs
          .queryDownloadsFolder()
          .then((filePaths) =>
            filePaths.find((filePath) => filePath.endsWith(filename)),
          );

        if (!iconPath) {
          await Bindings.fs.downloadFile(installedApp.icon, filename);
          iconPath = await Bindings.fs
            .queryDownloadsFolder()
            .then((filePaths) =>
              filePaths.find((filePath) => filePath.endsWith(filename)),
            );
        }
      }

      await Bindings.tabs.createWindow(
        uid,
        appUrl,
        installedApp.name,
        iconPath,
      );

      if (!options?.hideOnLaunch) {
        await Bindings.tabs.hideWindow(uid);
      }

      tabUidToLibraryItemApplication.set(uid, item);

      await Bindings.tabs.executeJs(uid, $env.PIPE_CLIENT_JS);

      await Bindings.tabs.executeJs(
        uid,
        `window.addEventListener('load', () => window.emitter.emit("v1.App.onNavigation"));`,
      );

      // ...
    } else {
      window.open(appUrl);
    }
  };

  console.log("ensuring apps");
  installedApps.ensure().then(async (installedApps) => {
    for (const installedApp of installedApps) {
      if (installedApp.item) {
        await launchApp(installedApp.item);
        await sleep(1000);
      } else {
        console.log("!installedApp.item");
      }
    }
  });

  return {
    installedApps,
    carouselApps,

    launchApp,
    uninstallApp,
  };
});
