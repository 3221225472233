import Bindings from "../bindings";

export const onMessageFromTab = (
  cb: (payload: string, uid: number) => Promise<void>,
) => {
  // console.log("binding our message receiver");
  Bindings.tabs.registerMsgReceiver((payload: string, uid: number) => {
    // console.log(`Bindings.tabs.registerMsgReceiver`, { payload, uid });
    cb(payload, uid);
  });
};
