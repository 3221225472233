import AppModule from "./v1/AppModule";
import ScenesModule from "./v1/ScenesModule";
import SourcesModule from "./v1/SourcesModule";
import HotkeysModule from "./v1/HotkeysModule";
import ThemeModule from "./v1/ThemeModule";
import type { InstalledApp } from "~/stores/apps.store";

function createBoundProxy<T extends object>(moduleInstance: T): T {
  return new Proxy(moduleInstance, {
    get(target, prop: string | symbol) {
      const value = target[prop as keyof T];

      if (typeof value === "function") {
        console.log({ prop }, value.name, value.prototype);
        return value.bind(target);
      }

      return value;
    },
  });
}

export default class PlatformApps {
  readonly v1: {
    Sources: SourcesModule;
    App: AppModule;
    Scenes: ScenesModule;
    Hotkeys: HotkeysModule;
    Theme: ThemeModule;
  };

  readonly apiReady = Promise.resolve();

  constructor(readonly installedApp: InstalledApp) {
    this.v1 = {
      Sources: createBoundProxy(new SourcesModule(this)),
      App: createBoundProxy(new AppModule(this)),
      Scenes: createBoundProxy(new ScenesModule(this)),
      Hotkeys: createBoundProxy(new HotkeysModule(this)),
      Theme: createBoundProxy(new ThemeModule(this)),
    };
  }
}
