import { PlatformAppsModule } from "../PlatformAppsModule";

export default class HotkeysModule extends PlatformAppsModule {
  unregisterAll() {
    console.warn("Method not implemented: v1.Hotkeys.unregisterAll");
  }

  registerKey() {
    console.warn("Method not implemented: v1.Hotkeys.registerKey");
  }
}
